// frontend/src/pages/Home.jsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Alert, AlertDescription } from '../components/ui/alert';
import { Label } from '../components/ui/label';
import { useToast } from '../components/ui/use-toast';
import { motion } from 'framer-motion';
import { FaSpinner } from 'react-icons/fa';

// Assets
import logo from '../assets/logo.png';

// Loading Transition Component
import PokerLoadingTransition from '../components/auth/LoadingTransition';

function Home() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { toast } = useToast();

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showTransition, setShowTransition] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (error) setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      // Attempt login
      await login(formData.email, formData.password);

      // Only on successful login do we show the loading transition.
      setShowTransition(true);
    } catch (err) {
      console.error('Login error:', err);

      // Customize error message if it's related to the password.
      let message = err.message || 'Failed to login. Please check your credentials.';
      if (message.toLowerCase().includes('password')) {
        message = 'Incorrect password';
      }

      setError(message);
      toast({
        title: "Login Failed ♣️",
        description: message,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PokerLoadingTransition
        isVisible={showTransition}
        chipCount={20}
        cardCount={12}
        onComplete={() => {
          setShowTransition(false);
          toast({
            title: "Welcome back! ♠️",
            description: "Successfully logged in",
            variant: "success",
          });
          navigate('/dashboard', { replace: true });
        }}
      />

      {/* Overall Page Container */}
      <div className="min-h-screen bg-gradient-to-b from-poker-green to-poker-dark flex flex-col">

        {/* Top Banner with Large Logo */}
        <header className="w-full bg-black/50 flex items-center justify-center p-6 border-b border-poker-gold">
          <img src={logo} alt="SettleStack Logo" className="h-32 w-auto" />
        </header>

        {/* Main content area */}
        <main className="flex-grow max-w-7xl mx-auto px-6 py-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Column 1: Login/Register and Resources */}
            <div className="space-y-8">
              <Card className="bg-card-bg border-poker-gold backdrop-blur-sm">
                <CardContent className="pt-6 pb-8 px-6">
                  <div className="flex flex-col items-center">
                    <h2 className="text-2xl font-bold text-poker-gold mb-6" aria-label="Sign In to SettleStack">
                      Sign In to SettleStack
                    </h2>

                    {error && (
                      <motion.div
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.2 }}
                        className="w-full mb-6"
                      >
                        <Alert variant="destructive">
                          <AlertDescription>{error}</AlertDescription>
                        </Alert>
                      </motion.div>
                    )}

                    <form onSubmit={handleSubmit} className="w-full space-y-4" aria-describedby="signin-form">
                      <div className="space-y-2">
                        <Label htmlFor="email" className="text-poker-gold">Email</Label>
                        <Input
                          id="email"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                          aria-label="Email Address"
                          className="bg-black/20 border-poker-gold text-yellow-100 placeholder-yellow-200 focus:ring-poker-gold"
                          disabled={isLoading}
                        />
                      </div>

                      <div className="space-y-2">
                        <Label htmlFor="password" className="text-poker-gold">Password</Label>
                        <Input
                          id="password"
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          required
                          aria-label="Password"
                          className="bg-black/20 border-poker-gold text-yellow-100 placeholder-yellow-200 focus:ring-poker-gold"
                          disabled={isLoading}
                        />
                      </div>

                      <Button
                        type="submit"
                        className="w-full bg-poker-gold text-poker-dark hover:bg-yellow-400 flex items-center justify-center space-x-2"
                        disabled={isLoading}
                        aria-label="Sign In"
                      >
                        {isLoading ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <span>Signing in...</span>
                          </>
                        ) : (
                          'Sign In'
                        )}
                      </Button>
                    </form>

                    <div className="flex flex-col items-center gap-2 mt-6 text-sm text-poker-gold">
                      <div>
                        New to SettleStack?{' '}
                        <Button
                          variant="link"
                          className="p-0 h-auto text-poker-gold hover:text-yellow-400"
                          onClick={() => navigate('/register')}
                          aria-label="Create an account"
                        >
                          Create an account »
                        </Button>
                      </div>
                      <Button
                        variant="link"
                        className="text-poker-gold hover:text-yellow-400"
                        onClick={() => navigate('/forgot-password')}
                        aria-label="Forgot your password?"
                      >
                        Forgot your password?
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card
                className="bg-card-bg border-poker-gold backdrop-blur-sm cursor-pointer hover:shadow-lg transition"
                onClick={() => navigate('/resources')}
              >
                <CardContent className="p-6">
                  <h3 className="text-2xl font-semibold text-poker-gold mb-3" aria-label="Resources">
                    Resources
                  </h3>
                  <p className="text-yellow-200 text-sm leading-relaxed mb-4">
                    Explore a variety of poker resources to sharpen your skills and enhance your game:
                  </p>
                  <ul className="list-disc pl-6 text-yellow-200 space-y-2">
                    <li><strong>Hand Ranking Charts:</strong> Quickly reference which hands outrank others.</li>
                    <li><strong>Player Types:</strong> Understand different player archetypes and how to adjust your strategy.</li>
                    <li><strong>Video Tutorials:</strong> Learn advanced techniques and tips from seasoned pros.</li>
                    <li><strong>Strategy Guides:</strong> Dive deep into poker theory, from bluffing to optimal betting strategies.</li>
                  </ul>
                  <Button
                    className="mt-4 bg-poker-gold text-poker-dark hover:bg-yellow-400"
                    onClick={() => navigate('/resources')}
                  >
                    View All Resources »
                  </Button>
                </CardContent>
              </Card>
            </div>

            {/* Column 2: What is SettleStack? & Video */}
            <div className="space-y-8">
              <Card className="bg-card-bg border-poker-gold backdrop-blur-sm">
                <CardContent className="p-6">
                  <h3 className="text-2xl font-semibold text-poker-gold mb-2" aria-label="What is SettleStack?">
                    What is SettleStack? 🃏
                  </h3>
                  <p className="text-yellow-200 text-sm leading-relaxed">
                    SettleStack is your go-to solution for a smooth poker night. After a game full of big bets and bold bluffs, figuring out who owes who can be the hardest part—especially if the drinks were flowing. That’s where we come in.
                    <br /><br />
                    <strong>Here’s how SettleStack makes settling up easy:</strong>
                    <ul className="list-disc pl-6 mt-2 mb-4">
                      <li><strong>Host a Game:</strong> Start a game with a join code and invite your group.</li>
                      <li><strong>Set a Timer:</strong> Choose how long the game lasts.</li>
                      <li><strong>Count the Chips:</strong> Once the game ends, the host enters everyone’s final chip totals.</li>
                      <li><strong>Get the Results:</strong> We calculate exact payouts so everyone knows who owes who—no math, no confusion.</li>
                    </ul>
                    <strong>And there’s more:</strong>
                    <ul className="list-disc pl-6 mt-2">
                      <li><strong>Track Your Stats:</strong> See how you stack up and improve your strategy.</li>
                      <li><strong>Unlock Achievements:</strong> Earn milestones and rewards.</li>
                      <li><strong>Personalize Your Profile:</strong> Stand out with a custom profile picture.</li>
                    </ul>
                    <br />
                    Whether you’re playing for big stakes or just bragging rights, SettleStack keeps the focus on the fun and takes care of the rest. Ready to up your game? 🃏
                  </p>
                </CardContent>
              </Card>

              <Card className="bg-black/50 border-poker-gold backdrop-blur-sm">
                <CardContent className="p-4">
                  <h3 className="text-2xl font-bold text-poker-gold mb-4 text-center">Watch Our Intro Video 🎥</h3>
                  <motion.div
                    className="w-full max-w-full"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2, duration: 0.6 }}
                  >
                    <video
                      controls
                      className="w-full rounded-lg shadow-md"
                      poster="/assets/videos/SettleStackIntroThumbnail.jpg"
                    >
                      <source src="/assets/videos/SettleStackIntro.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </motion.div>
                  <p className="text-yellow-200 text-center mt-2 text-sm">
                    If the video doesn’t play, try refreshing or check back later.
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>

          {/* Bottom row: Terms and How It Works */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
            <div></div> {/* Left side can remain empty */}
            <div className="flex flex-col items-end space-y-4">
              {/* Additional content (e.g., Terms) can be added here */}
            </div>
          </div>
        </main>

        {/* Footer */}
        <footer className="bg-poker-dark p-6">
          <div className="max-w-7xl mx-auto text-center text-poker-gold">
            © {new Date().getFullYear()} SettleStack. All rights reserved.
          </div>
          <div className="mt-2 space-x-4 text-center">
            <Button
              variant="link"
              onClick={() => navigate('/privacy-policy')}
              className="text-poker-gold hover:text-yellow-400"
              aria-label="Privacy Policy"
            >
              Privacy Policy
            </Button>
            <Button
              variant="link"
              onClick={() => navigate('/contact')}
              className="text-poker-gold hover:text-yellow-400"
              aria-label="Contact Us"
            >
              Contact Us
            </Button>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Home;
